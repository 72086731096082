import cn from 'classnames';
import {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import useReducedMotion from '@/hooks/useReducedMotion';
import Grid from '@/components/brochureV2/layout/Grid/Grid';
import Col, {Span, Breakpoint} from '@/components/brochureV2/layout/Grid/Col';
import {ColorSchemeEnum} from '@/enums';
import {getButtonTheme} from '@/components/brochureV2/primitive/Button/types';
import Pill from '@/components/brochureV2/primitive/Pill/Pill';
import Image from '@/components/shared/Image/Image';
import ImageWithCaption from '@/components/brochureV2/section/Image/shared/ImageWithCaption';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import Typography from '@/components/base/elements/Typography/Typography';
import {kebab} from '@/utils/utils';
import {useResizeObserver} from '@/hooks/useResizeObserver';

import type {SequencedContentProps} from './types';

/**
 * @deprecated
 */
export default function SequencedContentGrid({
  contents,
  blockHeadingClass,
}: SequencedContentProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const buttonTheme = getButtonTheme(ColorSchemeEnum.Light);
  const isReducedMotionPreferred = useReducedMotion(false);
  const listRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<any>([]);
  const [tallestItemHeight, setTallestItemHeight] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  // A tall screen is a screen taller than the tallest item
  const isTallScreen = useMediaQuery(`(min-height: ${tallestItemHeight}px)`);
  const threshold = isTallScreen ? 1.0 : 0.5;

  useEffect(() => {
    isReducedMotionPreferred
      ? document.querySelector('html')?.classList.remove('scroll-smooth')
      : document.querySelector('html')?.classList.add('scroll-smooth');
  }, [isReducedMotionPreferred]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, contents?.length || 0);
  }, [contents]);

  // Ignore IntersectionObserver events while scrolling
  useIntersectionObserver(
    itemsRef,
    (entries: IntersectionObserverEntry[]) => {
      if (isScrolling) return; // Ignore events while scrolling

      // Find the entry with the highest intersection ratio
      const mostInViewEntry = entries.reduce(
        (highest, entry) => {
          return entry.intersectionRatio > highest.intersectionRatio
            ? entry
            : highest;
        },
        {intersectionRatio: 0} as IntersectionObserverEntry,
      );

      if (mostInViewEntry.isIntersecting) {
        const el = mostInViewEntry.target as HTMLElement;
        setActiveIndex(parseInt(el.dataset.index || '0', 0));
      }
    },
    {threshold},
  );

  // Get the tallest item height
  useResizeObserver(() => {
    if (!listRef.current) return;
    const elements = listRef.current.querySelectorAll('.content-item');
    if (!elements) return;

    let h = 0;
    elements.forEach((element: Element) => {
      const el = element as HTMLElement;
      if (el.offsetHeight > h) h = el.offsetHeight;
    });

    setTallestItemHeight(h);
  }, listRef);

  // Handle direct navigation from a URL with a hash
  useEffect(() => {
    const loadListener = () => {
      const id = window.location.hash.substring(1);
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    };

    window.addEventListener('load', loadListener);

    return () => {
      window.removeEventListener('load', loadListener);
    };
  }, []);

  // Effect to stop ignoring IntersectionObserver events after hash change
  useEffect(() => {
    setIsScrolling(false);
  }, [location.hash]);

  const [activeIndex, setActiveIndex] = useState(0);
  const navDrawerWrapperClasses = cn('nav-drawer', 'hidden md:block');
  const contentKickerClassnames =
    'uppercase opacity-60 font-bold text-base leading-4 ';
  const contentHeadingClassnames = 'font-bold text-[2rem] leading-10';

  return (
    <Grid>
      <Col
        className={navDrawerWrapperClasses}
        span={{
          [Breakpoint.Default]: Span.Twelve,
          [Breakpoint.Md]: Span.Three,
        }}
      >
        <nav
          aria-label="Jump to section"
          className="top-legacy-global-header sticky mb-12 md:mb-0"
        >
          <ul>
            {contents?.map((content, index) => {
              // for fancy ids, use the kicker
              const id =
                content.useKickerAsId && content.kicker
                  ? kebab(content.kicker)
                  : `content-${index}`;

              return (
                <li
                  key={content.kicker}
                  className={cn(
                    'py-3 md:pl-6 md:border-l-[3px] transition-colors',
                    {
                      'border-black': index === activeIndex,
                      'border-shade-50': index !== activeIndex,
                    },
                  )}
                >
                  <a
                    href={`#${id}`}
                    className="text-shade-60 text-body-base font-medium"
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveIndex(index);
                      setIsScrolling(true); // Set the flag to start ignoring IntersectionObserver events
                      const element = document.getElementById(id);
                      if (element) {
                        element.scrollIntoView({behavior: 'smooth'});
                        navigate(`${location.pathname}#${id}`); // Change the URL to include the anchor
                      }
                    }}
                  >
                    {content.kicker}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </Col>
      <Col
        span={{
          [Breakpoint.Default]: Span.Twelve,
          [Breakpoint.Md]: Span.Nine,
        }}
      >
        <div ref={listRef}>
          {contents?.map((content, index) => {
            // for fancy ids, use the kicker
            const id =
              content.useKickerAsId && content.kicker
                ? kebab(content.kicker)
                : `content-${index}`;

            return (
              <div
                key={content.kicker}
                id={id}
                data-index={index}
                className="content-item border-shade-20 border-b py-16 first:pt-0 last:border-b-0 last:pb-0"
                ref={(el) => (itemsRef.current[index] = el)}
              >
                {content.imageWithCaption && (
                  <div className="mb-10">
                    <ImageWithCaption
                      {...content.imageWithCaption}
                      buttonTheme={buttonTheme}
                    />
                  </div>
                )}

                <Typography
                  as="h2"
                  size="body-base"
                  className={
                    content.heading
                      ? contentKickerClassnames
                      : contentHeadingClassnames
                  }
                >
                  {content.kicker}
                </Typography>

                {content.heading && (
                  <Typography
                    as="h3"
                    size="body-base"
                    className={cn('mt-3', contentHeadingClassnames)}
                  >
                    {content.heading}
                  </Typography>
                )}
                <div className="grid-cols-2 gap-12 md:grid mt-12">
                  {content?.blocks?.map((block) => (
                    <div
                      key={block.heading}
                      className="mb-12 last:mb-0 md:mb-0"
                    >
                      <div className="mb-3 flex items-center">
                        <Typography
                          as={content.heading ? 'h4' : 'h3'}
                          size="body-base"
                          className={cn(
                            blockHeadingClass,
                            'font-bold text-xl leading-6',
                          )}
                        >
                          {block.heading}
                        </Typography>
                        {block.pill && (
                          <span className={cn('ml-2')}>
                            <Pill {...block.pill} />
                          </span>
                        )}
                        {block.icon?.src && (
                          <Image
                            alt={block.icon.alt}
                            className="mb-2 p-1 sm:mb-0"
                            src={block.icon.src}
                            width="64"
                            loading="lazy"
                          />
                        )}
                      </div>
                      <Typography
                        as="p"
                        size="body-base"
                        className="text-base leading-6"
                      >
                        {block.contentHtml}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </Col>
    </Grid>
  );
}
