import cn from 'classnames';

import Section from '@/components/brochureV2/primitive/Section/Section';

import Grid from './Grid';
import type {SequencedContentProps} from './types';

export {SequencedContentProps};

/**
 * @deprecated
 */
export default function SequencedContentServer({
  className,
  ...props
}: SequencedContentProps) {
  return (
    <Section
      className={cn(className, 'relative my-16')}
      sectionName="sequenced-content"
    >
      <Grid {...props} />
    </Section>
  );
}
